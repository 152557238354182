import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useScrollDirection } from "react-use-scroll-direction";
import "./Navigation.css";
import Logo from "../../assets/Koch-logo.png";
//import KnowledgeMegaMenu from "../MegaMenu/KnowledgeMegaMenu";
import { MegaMenu } from "../MegaMenu/MegaMenu";
import { MegaMenuCa } from "../MegaMenu/MegaMenuCa";
import ContactOverlay  from "../ContactOverlay/ContactOverlay";
import Close from "../../assets/Icons/close-icon.png";
import Menu from "../../assets/Icons/menu-icon.svg";
import RedClose from "../../assets/Icons/red-close.svg";
import BackArrow from "../../assets/Icons/arrow_back.png";
import RegionSelector from "../../components/RegionSelector";
import { toggleMegaMenu, showContactOverlay } from "../../store/region";

export default function Navigation() {
  const dispatch = useDispatch();
  const region = useSelector((state) => state.region.currentRegion);
  const getMegaMenu = useSelector((state) => state.region.getMegaMenu);
  const location = useLocation();
  // Scroll Direction
  const { scrollDirection } = useScrollDirection();
  const [scrollStyleDirection, setScrollStyleDirection] = useState("UP");
  /*const [getMegaMenu, setMegaMenu] = useState(false);*/
  const [getKnowledgeMegaMenu, setKnowledgeMegaMenu] = useState(false);
  const [getToggleMenu, setToggleMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [getProductCampaign, setProductCampaign] = useState("");
  const [showContact, setShowContact] = useState(false);
  const menuToggle = () => {
    setToggleMenu(!getToggleMenu);
    setKnowledgeMegaMenu(false);
    dispatch(toggleMegaMenu(false));
  };
  useEffect(() => {
    if (scrollDirection != null) {
      setScrollStyleDirection(scrollDirection);
    }
  }, [scrollDirection]);

    const CAlocation = useLocation();

    const isProtivateCaUrl = CAlocation.pathname.includes('protivate-ca');
    const [isProtivateVisible, setIsProtivateVisible] = useState(isProtivateCaUrl);

    const handleCloseStatusBar = () => {
        setIsProtivateVisible(false);
    };

  const showNavigation = () => setScrollStyleDirection("UP");
  const [scrollPosition, setScrollPosition] = useState("docked");
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition < 1) {
      setScrollPosition("docked");
    } else {
      setScrollPosition("undocked");
    }
  };
  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const megaMenu = () => {
    dispatch(toggleMegaMenu(true));
    setKnowledgeMegaMenu(false);
  };
  const knowledgeMenu = () => {
    setKnowledgeMegaMenu(!getKnowledgeMegaMenu);
    dispatch(toggleMegaMenu(false));
  };

  const closeMegaMenu = () => {
    dispatch(toggleMegaMenu(false));
    setKnowledgeMegaMenu(false);
    setActiveMenu(true);
    setProductCampaign("");
  };
  
  const closeAllMenus = () => {
    dispatch(toggleMegaMenu(false));
    setKnowledgeMegaMenu(false);
    setToggleMenu(false);
    setActiveMenu(false);
  };

    const [showProductSelector, setshowProductSelector] = useState(false);
    const hideNavbarPath = '/product-selector';
    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.pathname === hideNavbarPath) {
            setshowProductSelector(true);
        } else {
            setshowProductSelector(false);
        }
    }, [hideNavbarPath, navigate, showProductSelector]);

  //useEffect(() => {
  //    if (getMegaMenu === false) {
  //        document.body.style.overflow = 'unset';
  //    } else {
  //        document.body.style.overflow = 'hidden';
  //    }
  //}, [getMegaMenu]);

  useEffect(() => {
    if (
      getToggleMenu === false &&
      getMegaMenu === false &&
      getKnowledgeMegaMenu === false
    ) {
      document.body.style.overflow = "unset";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [getToggleMenu, getMegaMenu, getKnowledgeMegaMenu]);

  useEffect(() => {
    closeAllMenus();
  }, [location.pathname]);
  const IntlNav = () => {
    return (
      <div
        className={
          "navigation-wrap " +
          scrollPosition +
          " " +
          scrollStyleDirection +
          " " +
          (getToggleMenu === true ? "active-menu-bar" : "") + " " + (showProductSelector === true ? "d-none" : '')
        }
        id="top"
        role="navigation"
      >
        <div className="navigation-seat">&nbsp;</div>
        <a className="skip-nav-link" href="#content">
          Skip to main content
            </a>
        
        <div className="navigation-bar">
          <div className="navigation-bar-center">
            <button
              className={
                "navigation-bar-menu-button " +
                (getToggleMenu === true ? "activeIcon" : "")
              }
              onClick={menuToggle}
              title="Menu"
            >
              <img src={Menu} alt="logo" className={"menu-icon-mo"} width="26" height="16" />
              <img src={Close} alt="logo" className={"close-icon-mo"} width="12" height="12" />
            </button>
            <div className={`navigation-bar-left`}>
              <Link
                className="navigation-bar-logo"
                to="/home-int"
                onFocus={showNavigation}
              >
                <img src={Logo} alt="Koch Agronomic Services Logo" width="278" height="90"/>
              </Link>
            </div>
            <div className="navigation-bar-right">
              <div
                className="navigation-bar-light-link"
                onFocus={showNavigation}
                onClick={()=> navigate('/contact')}
                style={{ cursor: "pointer" }}
              >
                Contact
              </div>

              <RegionSelector />
            </div>
          </div>
        </div>
        {showContact && (
          <ContactOverlay onClose={() => setShowContact(false)} />
        )}
      </div>
    );
  };
  if (region === 4) {
    return <IntlNav />;
  }
  return (
    <div
      className={
        "navigation-wrap " +
        scrollPosition +
        " " +
        scrollStyleDirection +
        " " +
        ((getMegaMenu || getKnowledgeMegaMenu) === true
          ? "openMegaMenu "
          : "") +
        (getToggleMenu === true ? "active-menu-bar" : "")
        + " " + (showProductSelector === true ? "d-none" : '')
      }
      id="top"
      role="navigation"
    >
      <div className="navigation-seat">&nbsp;</div>
      <a className="skip-nav-link" href="#content">
        Skip to main content
          </a>


      <div className="navigation-bar">
        {isProtivateVisible && (
            <div className={`protivatca-statusbar ${getMegaMenu ? 'dNone' : ''} ${ scrollPosition + " " + scrollStyleDirection +" " }`}>
                <p>ROCKET SEEDS&trade; is now PROTIVATE&trade;</p>
                <button onClick={handleCloseStatusBar}>
                    <img src={RedClose} alt="logo" className={"menu-icon-mo"} width="16" height="16" />
                </button>
            </div>
        )}
        <div className="navigation-bar-center">
          <button
            className={
              "navigation-bar-menu-button " +
              (getToggleMenu === true ? "activeIcon" : "")
            }
            onClick={menuToggle}
            title="Menu"
          >
            <img src={Menu} alt="logo" className={"menu-icon-mo"} width="26" height="16" />
            <img src={Close} alt="logo" className={"close-icon-mo"} width="12" height="12" />
          </button>
          <div
            className={`navigation-bar-left '  ${getProductCampaign} ${
              activeMenu === true ? "diFlex" : ""
            }`}
          >
            <Link
              className="navigation-bar-logo"
              to={"/"}
              onFocus={showNavigation}
            >
              <img src={Logo} alt="Koch Agronomic Services Logo" width="278" height="90"/>
            </Link>
            <div
              className={
                "mobile-navWrapper " +
                (getToggleMenu === true ? "openCollapse" : "")
              }
            >
              <Link
                className={
                  "navigation-bar-link " +
                  (getMegaMenu === true ? "active-menu" : "")
                }
                onClick={megaMenu}
                onFocus={showNavigation}
              >
                Solutions
              </Link>
              <Link
                className={
                  "navigation-bar-link " +
                  (getKnowledgeMegaMenu === true ? "active-menu" : "")
                }
                to="/knowledge-center"
                onFocus={showNavigation}
              >
                Knowledge Center
              </Link>
              <Link
                className="navigation-bar-link"
                to="/about"
                onFocus={showNavigation}
              >
                About
              </Link>
              <Link
                className="navigation-bar-link"
                to={region === 3 ? "/find-a-rep-ca" : "/find-a-rep"}
                onFocus={showNavigation}
              >
                Retailers &amp; Reps
              </Link>
              <div className="mobile-res-leftBar">
                <Link
                  className="navigation-bar-link"
                  to="/product-labels"
                  onFocus={showNavigation}
                >
                  Labels / SDS
                </Link>
                <div
                  className="navigation-bar-link"
                  onFocus={showNavigation}
                  onClick={()=> navigate('/contact')}
                  style={{ cursor: "pointer" }}
                >
                  Contact
                </div>
              </div>
            </div>
          </div>
          <div className="navigation-bar-right">
            <Link
              className="navigation-bar-light-link"
              to="/product-labels"
              onFocus={showNavigation}
            >
              Labels / SDS
            </Link>
            <div
              className="navigation-bar-light-link"
              onFocus={showNavigation}
              style={{ cursor: "pointer" }}
              onClick={() =>region === 2 ? navigate('/contact') : navigate('/contact-ca')}
            >
              Contact
            </div>
            <div className="country-selector">
              <RegionSelector />
            </div>
          </div>
        </div>
      </div>
      {showContact && <ContactOverlay onClose={() => setShowContact(false)} />}
      {(getMegaMenu || getKnowledgeMegaMenu) && (
        <div className={"close-btn-wrapper"}>
          <button className={"cursor-pointer"} onClick={closeMegaMenu}>
            <img src={Close} alt="logo" className={"close-icon"} />
            <span className="back-arrow-button">
              <img src={BackArrow} alt="logo" className={"backArrow-icon"} />{" "}
              Back
            </span>
          </button>
          <button
            className={
              "navigation-bar-menu-button " +
              (getToggleMenu === true || toggleMegaMenu ? "activeIcon" : "")
            }
            onClick={menuToggle}
            title="Menu"
          >
            <span></span>
            <span></span>
            <span></span>
            <img src={Close} alt="logo" className={"close-icon-mo"} />
          </button>
        </div>
      )}

      {getMegaMenu && region == 2 && (
        <MegaMenu close={closeMegaMenu} ClickProduct={closeMegaMenu} />
      )}
      {getMegaMenu && region == 3 && (
        <MegaMenuCa ClickProduct={closeMegaMenu} />
      )}

      {/*getKnowledgeMegaMenu && <KnowledgeMegaMenu />*/}
    </div>
  );
}


