import React, { lazy } from 'react';

// Layout
import Layout from '../layouts/Layout';
import NoNavLayout from '../layouts/NoNavLayout';

const Home = lazy(() => import('../pages/Home'));
const Homeca = lazy(() => import('../pages/Home-ca'));
const Homeint = lazy(() => import('../pages/Home-int'));
const PressReleases = lazy(() => import('../pages/PressReleases/Index'));
const PressRelease = lazy(() => import('../pages/PressReleases/Detail'));
const Agrotain = lazy(() => import("../pages/Agrotain"));
const Centuro = lazy(() => import("../pages/Centuro"));
const Superu = lazy(() => import("../pages/Superu"));
const Protivate = lazy(() => import("../pages/Protivate"));
const Optrient = lazy(() => import("../pages/Optrient"));
const AnvolUS = lazy(() => import('../pages/Anvol-Us'));
const AnvolCA = lazy(() => import('../pages/Anvol-ca'));
const Superuca = lazy(() => import('../pages/Superuca'));
const Tribuneca = lazy(() => import('../pages/Tribuneca'));
const Agrotainca = lazy(() => import('../pages/Agrotainca'));
const Protivateca = lazy(() => import("../pages/Protivate-ca"));
const Knowledge = lazy(() => import('../pages/KnowledgeCenter/Index'));
const KnowledgeDetail = lazy(() => import('../pages/KnowledgeCenter/Detail'));
const LabelSds = lazy(() => import('../pages/ProductLabels/LabelSds'));
const ContactReps = lazy(() => import('../pages/ContactReps/index'));
const EUIRepsRetailers = lazy(() => import('../pages/ContactReps/EUIRepsRetailers'));
const RepsRetailersThankYou = lazy(() => import('../pages/ContactReps/thankyou'));
const WolfTrax = lazy(() => import('../pages/Wolf-trax'));
const WolfTraxCa = lazy(() => import('../pages/Wolf-Traxca'));
const Centuroca = lazy(() => import('../pages/Centuroca'));
const RocketSeedsCa = lazy(() => import('../pages/Rocket-Seeds-ca'));
const Nitamin = lazy(() => import('../pages/Nitamin'));
const Synthosca = lazy(() => import('../pages/Synthosca'));
const CampaignCenturo = lazy(() => import('../pages/Campaign/Centuro'));
const CampaignWolfTrax = lazy(() => import('../pages/Campaign/WolfTrax'));
const KasBrand = lazy(() => import('../pages/Campaign/KasBrand'));
const CampaignProtivate = lazy(() => import('../pages/Campaign/Protivate'));
const CampaignAnvol = lazy(() => import('../pages/Campaign/Anvol'));
const ProductSelector = lazy(() => import('../components/MegaMenu/ProductSelector'));
const ContactOverlay = lazy(() => import('../components/ContactOverlay/ContactOverlay'));
const regionCanada = 3;
const regionUS = 2;
const CanadaDomains = ['kochagronomicservices.ca'];

const IsCanadianDomain = () => {
	console.log(CanadaDomains);
	console.log(CanadaDomains.filter(a => window.location.hostname.indexOf(a) >= 0));
	return CanadaDomains.filter(a => window.location.hostname.indexOf(a) >= 0).length > 0;
}

export const appRoutes = [
	{
		path: '/',
		element: () => { return IsCanadianDomain() ? <Homeca></Homeca> : <Home></Home> },
		layout: Layout,
		region: () => { return IsCanadianDomain() ? regionCanada : regionUS },
	},
	{
		path: 'home-ca',
		element: Homeca,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'can',
		element: Homeca,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'home-int',
		element: Homeint,
		layout: Layout,
		region: 4
	},
	{
		path: 'int',
		element: Homeint,
		layout: Layout,
		region: 4
	},
	{
		path: 'agrotain',
		element: Agrotain,
		layout: Layout,
		region: regionUS
	},
	{
		path: 'centuro',
		element: Centuro,
		layout: Layout,
		region: regionUS
	},
	{
		path: 'superu',
		element: Superu,
		layout: Layout,
		region: regionUS
	},
	{
		path: 'protivate',
		element: Protivate,
		layout: Layout,
		region: regionUS
	},
	{
		path: 'optrient',
		element: Optrient,
		layout: Layout,
		region: regionUS
	},
	{
		path: 'anvol',
		element: AnvolUS,
		layout: Layout,
		region: regionUS
	},
	{
		path: 'anvol-ca',
		element: AnvolCA,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'superu-ca',
		element: Superuca,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'tribune-ca',
		element: Tribuneca,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'agrotain-ca',
		element: Agrotainca,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'protivate-ca',
		element: Protivateca,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'knowledge-center',
		element: Knowledge,
		layout: Layout,
	},
	{
		path: 'knowledge-center/category/:category',
		element: Knowledge,
		layout: Layout,
	},
	{
		path: 'knowledge-center/:slug',
		element: KnowledgeDetail,
		layout: Layout,
		region: regionUS
	},
	{
		path: 'can/knowledge-center/:slug',
		element: KnowledgeDetail,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'product-labels',
		element: LabelSds,
		layout: Layout,
	},
	{
		path: 'find-a-rep',
		element: ContactReps,
		layout: Layout,
	},
	{
		path: 'can/find-a-rep',
		element: ContactReps,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'reps-retailers',
		element: EUIRepsRetailers,
		layout: Layout,
	},
	{
		path: 'wolf-trax',
		element: WolfTrax,
		layout: Layout,
		region: regionUS
	},
	{
		path: 'wolf-trax-ca',
		element: WolfTraxCa,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'centuro-ca',
		element: Centuroca,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'rocket-seeds-ca',
		element: RocketSeedsCa,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'nitamin-ca',
		element: Nitamin,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'synthos-ca',
		element: Synthosca,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'clear-choice',
		element: CampaignCenturo,
		layout: Layout,
	},
	{
		path: 'balanced-nutrition',
		element: CampaignWolfTrax,
		layout: Layout,
	},
	{
		path: 'thats-farming',
		element: KasBrand,
		layout: Layout,
	},
	{
		path: 'anvol-protects',
		element: CampaignAnvol,
		layout: Layout,
	},
	{
		path: 'dual-purpose',
		element: CampaignProtivate,
		layout: Layout,
		region: regionUS
	},
	{
		path: 'dual-purpose-ca',
		element: CampaignProtivate,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'press-releases/:slug',
		element: PressRelease,
		layout: Layout,
		region: regionUS
	},
	{
		path: 'can/press-releases/:slug',
		element: PressRelease,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'press-releases',
		element: PressReleases,
		layout: Layout,
	},
	{
		path:'product-selector',
		element: ProductSelector,
		layout:NoNavLayout
	},
	{
		path:'contact',
		element:ContactOverlay,
		layout: Layout,
		region: regionUS
	},
	{
		path:'contact-ca',
		element:ContactOverlay,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'can/contact-us',
		element: ContactOverlay,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'find-a-rep-ca',
		element: ContactReps,
		layout: Layout,
		region: regionCanada
	},
	{
		path: 'retailer-thankyou',
		element: RepsRetailersThankYou,
		layout: Layout
	},
	{
		path: 'rep-thankyou',
		element: RepsRetailersThankYou,
		layout: Layout
	}
];
